
import React, {useState, useEffect} from "react"
import Layout from "../../layout"

import WebappForm from "../../webapps/form"
import SubmitDataField from "../../negosyonow/fields/submitdata"


const negosyonowAPI = require("../../../../lib/requestNegosyownow")
const webappsAPI = require("../../../../lib/requestWebapps")
const formatTools = require("../../../../lib/formatTools")

const ENTITYID="nnfranchiseuser"

const nnfranchisestatusoptionlist = [
	{"value":1,"display":"New"},
	{"value":2,"display":"Processing"},
	//{"value":3,"display":"Confirmed/Granted"},
	{"value":4,"display":"Declined"},
];

const nnfranchiseactivestatusoptionlist = [
	{"value":3,"display":"Active"},
	{"value":5,"display":"Cancelled"},
	{"value":6,"display":"Contract Expired"},
];


const nnfranchisepickerfields = [
	{"label":"Name", "dbfield": "nnfranchisepackagecode_name", "type": "text", "filtertype": "textbox"},
	{"label":"Description", "dbfield": "nnfranchisepackagecode_description", "type": "text", "filtertype": "textbox"}
];

const nnfranchisepickerfilter = [
	//{"field":"nnfranchise_id", "operation": ">", "value": 0},
];



const FranchiseuserPage = ({location, applicantpage}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [userformurl, setUserformurl] = useState("");
	const [userformdata, setUserformdata] = useState({});
	const [franchiseid, setFranchiseid] = useState(0);
	const [onspersonid, setOnspersonid] = useState(0);

	const searchFields = [
		{"label":"Date Registered", "dbfield": "nnfranchiseuser_dateregistered", "type": "datetime", "filtertype": "datetime"},
		{"label":"Status", "dbfield": "nnfranchiseuserstatus_id", "type": "combo", "filtertype": "combo", "options":applicantpage?nnfranchisestatusoptionlist:nnfranchiseactivestatusoptionlist},
		{"label":"Details", "dbfield": "nnfranchiseuser_resultsummary", "type": "text", "filtertype": "text"},
		{"label":"Last Updated", "dbfield": "nnfranchiseuser_dateupdated", "type": "datetime", "filtertype": "datetime"},
		{"label":"Name", "dbfield": "onsperson_name", "type": "text", "filtertype": "text"},
		{"label":"Franchise", "dbfield": "nnfranchise_name", "type": "text", "filtertype": "text"},
		{"label":"Mobile #", "dbfield": "nnfranchiseuser_mobilenum", "type": "text", "filtertype": "text"},
		{"label":"Email", "dbfield": "nnfranchiseuser_email", "type": "text", "filtertype": "text"},
	];


	const formFields = [
		[
			{"label":"Date Registered", "field": "nnfranchiseuser_dateregistered", "value": "", "input": "datetime", "mode": "readonly"},
			{"label":"Last Updated", "field": "nnfranchiseuser_dateupdated", "value": "", "input": "datetime", "mode": "readonly"},
			{"label":"First Name", "field": "onsperson_firstname", "value": "", "input": "picker", "mode": "readonly"},
			{"label":"Last Name", "field": "onsperson_lastname", "value": "", "input": "text", "mode": "readonly"},
			{"label":"Franchise", "field": "nnfranchise_name", "value": "", "input": "picker", "mode": "readonly"},
			{"label":"Package", "field": "nnfranchisepackagecode_name", "value": "", "input": "picker", "mode": "required", "pickerfields": nnfranchisepickerfields, "pickerfilters": nnfranchisepickerfilter},
			{"label":"Mobile #", "field": "nnfranchiseuser_mobilenum", "value": "", "input": "textbox", "mode": "readonly"},
			{"label":"email", "field": "nnfranchiseuser_email", "value": "", "input": "textbox", "mode": "readonly"},
			{"label":"Details", "field": "nnfranchiseuser_submiturl", "value": "", "input": "textarea", "mode": "readonly"},
			{"label":"Response", "field": "nnfranchiseuser_resultsummary", "value": "", "input": "textarea", "mode": "normal"},
			{"label":"Status", "field": "nnfranchiseuserstatus_id", "value": "", "input": "combo", "mode": "required", "options": applicantpage?nnfranchisestatusoptionlist:nnfranchiseactivestatusoptionlist},
			//{"label":"Re-apply Date", "field": "nnfranchiseuser_applyblacklistdateend", "value": "", "input": "date", "mode": "normal"},
			{"label":"Person ID", "field": "onsperson_id", "value": "", "input": "hidden", "mode": "readonly"},
			{"label":"Franchise ID", "field": "nnfranchise_id", "value": "", "input": "hidden", "mode": "readonly"},

		]
	];
	useEffect(() => {
		function loadFormdata(formurl)
		{
			negosyonowAPI.loadFormData(formurl, token).then(dataresponse => {
				setUserformdata(dataresponse);
			});
		}

		loadFormdata(userformurl)
	}, [userformurl, token]);


	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		if (franchiseid < 1) {
			callback({"status":"Error", "message":"Unable to process data, please try again"});
			return;
		}

		params.nnfranchiseuser_dateupdated = formatTools.getDateStr();
		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				negosyonowAPI.buildEntitybyParam({"id":franchiseid, "mode": "user"},token, "franchise").then(nnresponse => {
					negosyonowAPI.buildEntitybyParam({"id":onspersonid, "mode": "exclusivelist"},token, "userprofile").then(nnuserresponse => {
						callback(response);
					});
				});
			} else {
				callback(response);
			}
		});

		//callback({"status":"Error"});
	}

	function customFieldFragment(inputlist, idx, isdisabled, fieldid, inputchangecallback)
	{
		if (inputlist[idx].field === "nnfranchiseuser_submiturl") {
			if (inputlist[idx].value) {
				if (inputlist[idx].value !== "") {
					setUserformurl(inputlist[idx].value);
					return displayFormData(inputlist[idx].label, userformdata);
				}
			} else {
				return <></>
			}
		}
		return null;
	}

	function customValidateValues(mainform, subformlist, footerformlist)
	{
		var statusidval = "";
		var summaryval = "";
		var packagecodeid = 1;
		var idx = 0;
		while (idx < mainform.length) {
			if (mainform[idx].field === "nnfranchise_id") {
				setFranchiseid(mainform[idx].value);
			} else if (mainform[idx].field === "onsperson_id") {
				setOnspersonid(mainform[idx].value);
			} else if (mainform[idx].field === "nnfranchisepackagecode_id") {
				packagecodeid = parseInt(mainform[idx].value,10);
			} else if (mainform[idx].field === "nnfranchiseuserstatus_id") {
				statusidval = parseInt(""+mainform[idx].value,10);
			} else if (mainform[idx].field === "nnfranchiseuser_resultsummary") {
				summaryval = mainform[idx].value;
			}
			idx++;
		}
		if (packagecodeid <= 1) {
			return {"status":"Error", "message":"Please select package"};
		} else if (statusidval > 3 && summaryval.length < 1) {
			return {"status":"Error", "message":applicantpage?"Please provide reason for declining in the response":"Please provide details disabling the franchise"};
		}
		return {"status":"OK"};
	}

	function customEditFieldInfo(inputlist, idx)
	{
		// 2024-01-10: Moved changing of package type to different section
		const fieldlist = ["nnfranchiseuserstatus_id", "nnfranchiseuser_resultsummary", "nnfranchiseuser_applyblacklistdateend", "nnfranchisepackagecode_name"];
		if (!fieldlist.includes(inputlist[idx].field) || applicantpage === false) {
			return inputlist[idx];
		}
		var statusidval = 0;
		var tmpidx = 0;

		while (tmpidx < inputlist.length) {
			if (inputlist[tmpidx].field === "nnfranchiseuserstatus_id") {
				if (inputlist[tmpidx].modified) {
					return inputlist[idx];
				}
				statusidval = parseInt(""+inputlist[tmpidx].value,10);
				break;
			}
			tmpidx++;
		}

		if (statusidval < 3) {
			// New, Processing or not found
			return inputlist[idx];
		}

		var tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
		tmpfield.mode = "readonly";
		return tmpfield;
	} // customEditFieldInfo

	function customPicker(pkid, inputList, inputSubList, inputFooterList, entry, fieldid, callback)
	{
		if (entry.field !== "nnfranchisepackagecode_name") {
			callback(entry);
			return;
		}
		// get franchise id and set as filter
		var newentry = JSON.parse(JSON.stringify(entry));
		var tmpidx = 0;
		var nnfranchise_id = 0;
		while (tmpidx < inputList.length) {
			if (inputList[tmpidx].field === "nnfranchise_id") {
				nnfranchise_id = parseInt(inputList[tmpidx].value, 10);
			}
			tmpidx++;
		}
		newentry.pickerfilters = [
			{"field":"nnfranchise_id", "operation": "=", "value": nnfranchise_id}
		];
		callback(newentry);
	}


	function displayFormData(title, formdata)
	{
		const labelformatlist = {
			"Date of Birth":"date"
		};
		return <SubmitDataField
					datatitle={title}
					formdata={formdata}
					labelformatlist={labelformatlist}
				/>
	}

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				pagetitle={pagetitle}
				allowadd={false}
				allowdelete={false}
				mobilerowtitlefield={["onsperson_name"]}
				searchFields={searchFields}
				editFields={formFields}
				userSearchParam={{"orderby":"NNFRANCHISEUSER.nnfranchiseuserstatus_id,nnfranchiseuser_dateregistered"}}
				userSearchFilter={[{"field":"nnfranchiseuserstatus_id", "operation":"in", "value": applicantpage?"(1,2,4)":"(3,5,6)"}]}
				customSubmit={customSubmit}
				customFieldFragment={customFieldFragment}
				customEditFieldInfo={customEditFieldInfo}
				customValidateValues={customValidateValues}
				customPicker={customPicker}
				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default FranchiseuserPage;
